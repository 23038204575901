import { useRoute } from "vue-router";
import { authenticationService } from "~/core/services";

export const useMeetingId = () => {
  const route = useRoute();
  const meetingId = ref<string | null>(null);

  const meetingIdParam = route.params.meetingId;

  if (Array.isArray(meetingIdParam)) {
    // FIX ME : what's happen ?
    throw Error("Bad route");
  }

  meetingId.value = meetingIdParam;

  watch(
    () => !route.params.meetingId && route.fullPath,
    () => {
      if (!meetingIdParam) {
        route.query.meetingId && !Array.isArray(route.query.participantId)
          ? (meetingId.value = route.query.meetingId as string)
          : (meetingId.value = null);
      }
    },
    { deep: true, immediate: true },
  );

  return meetingId;
};

export const useNoteId = () => {
  const route = useRoute();
  const noteId = route.params.noteId;

  if (Array.isArray(noteId)) {
    // FIX ME : what's happen ?
    throw Error("Bad route");
  }

  return noteId;
};

export const useStagiaireId = () => {
  const route = useRoute();

  const stagiaireId = ref<string | null>(null);

  const stagiaireIdParam = route.params.participantId;

  if (Array.isArray(stagiaireIdParam)) {
    // FIX ME : what's happen ?
    throw Error("Bad route");
  }

  stagiaireId.value = stagiaireIdParam;

  watch(
    () => !route.params.stagiaireId && route.fullPath,
    () => {
      if (!stagiaireIdParam) {
        route.query.participantId && !Array.isArray(route.query.participantId)
          ? (stagiaireId.value = route.query.participantId)
          : (stagiaireId.value = null);
      }
    },
    { deep: true, immediate: true },
  );

  return stagiaireId;
};

export const useTeamId = () => {
  const route = useRoute();
  const teamId = route.params.teamId;

  if (Array.isArray(teamId)) {
    // FIX ME : what's happen ?
    throw Error("Bad route");
  }

  return teamId;
};

export const useDailyTaskTeamId = () => {
  const route = useRoute();
  const dailyTaskTeamId = route.params.dailyTaskTeamId;

  if (Array.isArray(dailyTaskTeamId)) {
    // FIX ME : what's happen ?
    throw Error("Bad route");
  }

  return dailyTaskTeamId;
};

export const useResetPassIds = () => {
  const route = useRoute();
  const token = route.query.token;
  const uid = route.query.uid;

  if (token && uid) {
    if (Array.isArray(token) || Array.isArray(uid)) {
      // FIX ME : what's happen ?
      throw Error("Bad route");
    }
    return { token: token, uid: uid };
  } else {
    return { token: "", uid: "" };
  }
};
export const useQRCode = async () => {
  const route = useRoute();
  const qrcode_token = route.query.qrcode_token;
  const meeting_uuid = route.query.meeting_uuid;

  if (qrcode_token && meeting_uuid) {
    if (Array.isArray(qrcode_token) || Array.isArray(meeting_uuid)) {
      // FIX ME : what's happen ?
      throw Error("Bad route");
    }

    authenticationService.scanCode({
      qrCodeToken: qrcode_token,
      meetingId: meeting_uuid,
    });

    await navigateTo(RouteUtils.getMeetingHome(meeting_uuid));
  }
};

export const useFormId = () => {
  const route = useRoute();
  const formId = route.params.formId;

  if (Array.isArray(formId)) {
    // FIX ME : what's happen ?
    throw Error("Bad route");
  }

  return formId;
};
